// import { lazy } from 'react';
// import { env } from '../../shared/functional/global-import';

// Configure all routes of the application that contain no template
const NoTemplateRoutesConfing = [
  // {
  //   exact: true,
  //   private: false,
  //   path: `${env.PUBLIC_URL}/create-account`,
  //   component: lazy(() => import('../../pages/dashboard/dashboard-container'))

  // },
];
export default NoTemplateRoutesConfing;