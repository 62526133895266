import React, { Component } from "react";
import { Image, Menu, Button, Icon } from "semantic-ui-react";
import { Link, env, connect, bindActionCreators, actions, withRouter } from "../../../functional/global-import";
import LogoImg from "../../../../assets/images/logo.png";
import GraphicImage from "../../../../assets/images/US Veteran Owned Business.png";
// import { Select, Icon, List, Grid, Dropdown, Container, Checkbox, Input, Image, Menu, Button } from "semantic-ui-react";

const trigger = (
  <span>
    <span className="iconify" data-icon="akar-icons:circle-plus" data-inline="false"></span>
  </span>
)
class WebsiteHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemStatus: false,
      orderStatus: false,
      memberStatus: false,
      locationOption: [],
      selectedLocation: "",
      isToShowColapseMenu: false
    };
  }
  state = { activeItem: 'home' }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name })
   this.onCollapseClose()
  } 

  onCollapseClick = () => {
    this.setState({ isToShowColapseMenu: true });
  }
  onCollapseClose = () => {
    this.setState({ isToShowColapseMenu: false });
  }
  render() {
    const { activeItem, isToShowColapseMenu } = this.state
    return (
      <React.Fragment>
        <div className="WebsiteHeader">
          <div className="brandName">
            <Link className="logoImage" as={Link} to={`${env.PUBLIC_URL}/`}>
              <Image src={LogoImg} alt="AHOY Booking" title="AHOY Booking"/>
            </Link>
            <Image src={GraphicImage} className="veteranLogo" alt="U.S. Veteran Owned Business" title="U.S. Veteran Owned Business"/>
            {/* <span className="ContentArea">*Previously Named "Rehnt It” </span> */}
            
          </div>
          <Menu secondary position='center' textAlign="center" className="navigationBar desktopOnly">
            <Menu.Item
              name='Schedule Demo'
              active={activeItem === 'ScheduleDemo'}
              onClick={this.handleItemClick}
              as={Link}
              to="schedule-demo"
            />
            <Menu.Item
              name='Sign Up'
              active={activeItem === 'SignUp'}
              onClick={this.handleItemClick}
              as={Link}
              to="/create-account"
            />
          </Menu>
          <Button className="orange-react-btn tryFreeBtn desktopOnly" as={Link} to={`${env.PUBLIC_URL}/create-account`}>Try for free</Button>

          {/* Mobile Menu  */}
          <Icon name="bars" className="mobileOnly" size="large" onClick={this.onCollapseClick} />
          <div
            className={`mobileMenu mobileOnly ${isToShowColapseMenu ? "show" : ""}`}
          >
            <Icon name="close" className="close" onClick={this.onCollapseClose} />
            <Menu secondary position='center' textAlign="center" className="navigationBar">
              <Menu.Item
                name='Schedule Demo'
                active={activeItem === 'ScheduleDemo'}
                onClick={this.handleItemClick}
                as={Link}
                to="schedule-demo"
              />
              <Menu.Item
                name='Sign Up'
                active={activeItem === 'SignUp'}
                onClick={this.handleItemClick}
                as={Link}
                to="/create-account"
              />
            </Menu>
            <Button className="orange-react-btn tryFreeBtn" as={Link} to={`${env.PUBLIC_URL}/create-account`} onClick={this.onCollapseClose}>Try for free</Button>
          </div>
          {/*  */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeLocationSelected: bindActionCreators(actions.storeLocationSelected, dispatch),
      storeCustomerDetail: bindActionCreators(actions.storeCustomerDetail, dispatch),
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebsiteHeader));