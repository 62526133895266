import React, { Component } from "react";
import { Button, Modal, Form, Input, Grid, Checkbox } from "semantic-ui-react";
import { commonFunctions, env, connect, bindActionCreators, actions, withRouter, Link } from "../.././../../functional/global-import";

class ResetPasscodePasswordModal extends Component {
  get initialState() {
    return {
      resetPassword: {
        password: "",
        confirmPassword: "",
        passcode: "",
        confirmPasscode: "",
        isActive: true,
        userId: "",
        actionPerformedBy: ""
      }
    };
  }

  constructor(props) {
    super(props);
    this.state = { ...this.initialState };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  // componentWillReceiveProps = (props,nextprops) => {
  //   ;
  //   // if (nextprops.userId && props.userId !== nextprops.userId) { 
  //   //   const { resetPassword } = this.state;
  //   //   resetPassword.userId = props.userId;
  //   //   this.setState({ resetPassword });
  //   // }
  //       const { resetPassword } = this.state;
  //       resetPassword.userId = props.userId;
  //       this.setState({ resetPassword });
  // }

  static getDerivedStateFromProps(props, state) {
    ;
    if (props.userId && props.openModal && props.userId !== state.userId) {
      return {
        resetPassword: {
          ...state.resetPassword,
          userId: props.userId
        }
      };
    }
  }

  // This function is used to set handle change
  onHandleChange = (e, { name, value, type, checked, data }) => {
    var resetPassword = commonFunctions.onHandleChange(e, { name, value, type, checked, data }, this.state.resetPassword);
    if (data === 'isActive') {
      this.setState(() => ({
        resetPassword: {
          ...this.state.resetPassword, password: "",
          confirmPassword: "",
          passcode: "",
          confirmPasscode: "",
          isActive: checked
        }
      }));
      this.validator = commonFunctions.initializeSimpleValidator();
    }
    else {
      this.setState({ resetPassword });
    }
  }

  // This function is used to set handle submit
  onHandleSubmit = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      const { resetPassword } = this.state;
      this.props.actions.apiCall({
        urls: ["UPDATEPASSCODEANDPASSWORD"], method: "PUT", data: this.state.resetPassword,
        onSuccess: (response) => {
          this.onCloseModal();
        }, showNotification: true
      });
    }
  }

  onCloseModal = () => {
    
    this.props.closeModal();
    this.setState(this.initialState);
  }


  render() {
    const { resetPassword } = this.state;
    return (
      <Modal open={this.props.openModal} closeIcon onClose={this.onCloseModal} size="tiny" >
        <Modal.Header>Reset Password & Passcode
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Grid>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Form.Group inline>
                    <span>Passcode &nbsp;&nbsp;</span>
                    <div className="amount-percentage">
                      <Checkbox
                        label="Password"
                        className="custom-toggle"
                        name="chkPasswordPasscode"
                        data="isActive"
                        checked={resetPassword.isActive}
                        onChange={this.onHandleChange}
                        value={resetPassword.isActive}
                      />
                    </div>
                  </Form.Group>
                </Grid.Column>

                {resetPassword.isActive === true && <Grid.Row columns="2">
                  <Grid.Column>
                    <Form.Input type="password" fluid textAlign="left" placeholder="Enter New Password"
                      name="txtPassword" data="password" onChange={this.onHandleChange}
                      error={resetPassword.isActive === true || resetPassword.isActive === false ?
                        this.validator.message('password', resetPassword.password,
                          'required|min:6') : ""} value={resetPassword.password}>
                    </Form.Input>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input type="password" fluid textAlign="left" placeholder="Confirm New Password"
                      name="txtConfirmPassword" data="confirmPassword" onChange={this.onHandleChange}
                      error={this.validator.message('confirmPassword', resetPassword.confirmPassword,
                        `required|validConfirmPassword:${resetPassword.password}`)} value={resetPassword.confirmPassword}>
                    </Form.Input>
                  </Grid.Column>
                </Grid.Row>}
                {resetPassword.isActive === false && <Grid.Row columns="2">
                  <Grid.Column>
                    <Form.Input type="password" fluid textAlign="left" placeholder="New Passcode"
                      name="txtPasscode" data="passcode" onChange={this.onHandleChange}
                      error={this.validator.message('passcode', resetPassword.passcode,
                        'required|integer|min:6|max:6')} value={resetPassword.passcode}></Form.Input>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input type="password" fluid textAlign="left" placeholder="Confirm New Passcode"
                      name="txtConfirmPasscode" data="confirmPasscode" onChange={this.onHandleChange}
                      error={this.validator.message('confirmPasscode', resetPassword.confirmPasscode,
                        `required|validConfirmPasscode:${resetPassword.passcode}`)} value={resetPassword.confirmPasscode}>
                    </Form.Input>
                  </Grid.Column>
                </Grid.Row>}
              </Grid>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onCloseModal} className="blue-btn"> Cancel </Button>
          <Button onClick={this.onHandleSubmit} className="orange-btn" loading={this.props.api.isApiLoading}> Update </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
    }
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasscodePasswordModal));

