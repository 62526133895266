import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { connect } from '../../../functional/global-import';

const GlobalCodeMultiSelect = ({ placeholder, name, onChange, value, categoryType, data, global, error }) => {
    const filteredGlobalCodes = global.codes.filter(code => code.categoryName === categoryType).map((filtercode) => {
        return { key: filtercode.codeName, value: filtercode.globalCodeId, text: filtercode.codeName }
    });
    return (
        <Dropdown options={filteredGlobalCodes} placeholder={placeholder}
            defaultValue={value}
            search selection fluid multiple allowAdditions value={value} onChange={onChange} name={name} className="tagger" data={data} error={error} type="multiple-select" />);
};

GlobalCodeMultiSelect.propTypes = {
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    data: PropTypes.string.isRequired,
    categoryType: PropTypes.string.isRequired,
    error: PropTypes.func
};


const mapStateToProps = state => {
    return {
        global: state.global
    };
};
export default connect(mapStateToProps, null)(React.memo(GlobalCodeMultiSelect));