import { lazy } from 'react';
import { env } from '../../shared/functional/global-import';

// Configure all routes of the application that contain authenticate template
const AuthenticateTemplateRoutesConfing = [
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL === '' ? '/' : env.PUBLIC_URL}`,
    component: lazy(() => import('../../pages/dashboard'))
    // component: lazy(() => import('../../pages/dashboard/dashboard-container'))
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/terms-of-service`,
    component: lazy(() => import('../../pages/terms-service'))
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/privacy-policy`,
    component: lazy(() => import('../../pages/privacy-policy'))
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/schedule-demo`,
    component: lazy(() => import('../../pages/schedule-demo'))
  },
  {
    exact: true,
    private: false,
    path: `${env.PUBLIC_URL}/create-account`,
    component: lazy(() => import('../../pages/dashboard/dashboard-container'))

  },
];



export default AuthenticateTemplateRoutesConfing;